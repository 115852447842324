import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurSolutions from "../components/Index/OurSolutions"
import OurServices from "../components/Index/OurServices"
import OurFeatures from "../components/Index/OurFeatures"
import RecentProjects from "../components/Index/RecentProjects"
import Testimonials from "../components/Index/Testimonials"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import FunFacts from '../components/SEOAgency/FunFacts'

import Minibanner from '../components/kevin/minibanner'


import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'




const Home = () => {
  return (
        <Layout>
            <Navbar />
        
  
            <Banner />

           <Minibanner />

            <OurServices />
       
            <RecentProjects />
   {/*          <Minibanner /> */}
            <OurSolutions />

    

            <Testimonials />

       
            <OurFeatures />    





           {/*  <TeamMember /> */}

           {/*  <Pricing /> */}

           {/*  <OurBlog /> */}
   
    
            <ProjectStartArea />

            <Footer />
        </Layout>
    )
}

export default Home