import React from 'react';
import { Link } from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'
import ProjectImage1 from '../../assets/images/projects/project1.jpg'
import ProjectImage2 from '../../assets/images/projects/project2.jpg'
import ProjectImage3 from '../../assets/images/projects/project3.jpg'

import shape7 from '../../assets/images/shape/vector-shape7.png'
import shape8 from '../../assets/images/shape/vector-shape8.png'


const RecentProjects = () => {
    return (
        <section className="projects-area bg-color pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
              {/*           <img src={StarIcon} alt="image" /> */} SOFTWARES
                    </span>
                    <h2>Nuestro software personalizables</h2>

                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">

                        <div className="single-projects-box">
                            <Link to="/cobranza/" className="link-btn">

                                <div className="image">
                                    <img src={ProjectImage1} alt="Project" />


                                </div>
                            </Link>
                            <Link to="/cobranza/">
                                <div className="content">

                                    <span class="default-btn">
                                Sistema de cobranza
                                    </span>
                                </div>
                            </Link>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">


                            <Link to="/workflow" className="link-btn">

                                <div className="image">
                                    <img src={ProjectImage2} alt="Project" />

                                </div>

                            </Link>

                            <Link to="/workflow/">
                                <div className="content">

                                    <span class="default-btn">
                                Sistema Workflow
                                    </span>
                                </div>
                            </Link>


                        </div>
                    </div>




                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">

                            <Link to="/seqgps" className="link-btn">

                                <div className="image">
                                    <img src={ProjectImage3} alt="Project" />
                                </div>
                            </Link>


                            <Link to="/seqgps/">
                                <div className="content">

                                    <span class="default-btn">
                               Seq GPS
                                    </span>
                                </div>
                            </Link>



                        </div>
                    </div>



                </div>

                
            <div className="vector-shape7">
                <img src={shape7} alt="features" />
            </div>
    
            <div className="vector-shape8">
                <img src={shape8} alt="features" />
            </div>
            </div>
        </section>
    )
}

export default RecentProjects;