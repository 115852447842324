import React from 'react';

import icon1 from '../../assets/images/icons/comercio.png'
import icon2 from '../../assets/images/icons/salud.png'
import icon8 from '../../assets/images/icons/publicidad.png'
import icon9 from '../../assets/images/icons/encuenta.png'
import icon10 from '../../assets/images/icons/finanaciero.png'
import icon11 from '../../assets/images/icons/bienes.png'




const Services = () => {
    return (
        <div className="boxes-area pb-70">
            <div className="container">
            <div className="section-title">
            <span className="sub-title">
                      {/*   <img src={StarIcon} alt="StarIcon" />  */}
                      Nuestra metodología de trabajo
                    </span> 
                    <h2>¡Te acompañamos en todo momento!</h2>
                </div>

                <div className="row">
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={icon1} alt="banner" />
                            </div>
                            <h3>

                            Necesidades del cliente
                                
 
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                    
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={icon2} alt="banner" />
                            </div>
                            <h3>

                            Planificación del proyecto
 
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>



                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={icon11} alt="banner" />
                            </div>
                            <h3>
    
                            Desarrollo de UX/UI 

                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>

                    

                <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={icon9} alt="banner" />
                            </div>
                            <h3>
              
                            Maquetación y Programación
                    
                            </h3>



                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}



                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}


                        </div>
                    </div>





                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={icon10} alt="banner" />
                            </div>
                            <h3>
                     
                            Pruebas y validación
                 
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={icon8} alt="banner" />
                            </div>
                            <h3>
                            
                            Seguimiento y evaluación
                          
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                </div>

















                <div className="row">
                    






                </div>




            </div>
        </div>
    )
}
export default Services;