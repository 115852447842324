import React from 'react'
import icon1 from '../../assets/images/funfacts/fun-icon1.png'
import icon2 from '../../assets/images/funfacts/fun-icon2.png'
import icon3 from '../../assets/images/funfacts/fun-icon3.png'
import icon4 from '../../assets/images/funfacts/fun-icon4.png'

 const Minibanner = () => {
    return (
        <section className="cta pt-100 pb-70">
            <div className="container">
                <div className="row">


                    <div className="col-lg col-sm col-12">
                        <div className="single-funfacts-item">
                  {/*           <div className="icon">
                                <img src={icon1} alt="fun" />
                            </div> */}
                            <h3>¡Te acompañamos a llevar tu empresa al siguiente!</h3>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default Minibanner;